//Zona de importacion de componentes externos.
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, useModal, ModalTransition } from "react-simple-hook-modal";
import ReactPixel from "react-facebook-pixel";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";
import { actualizarEventId } from "../../global/functions";

//Zona de importacion de Css.
import "../../static/css/Modal.css";

import TagManager from "react-gtm-module";
import { usePreventaContext } from "../../providers/PreventaProviders";

const ModalGeneral = ({
  modificarClienteState,
  clienteState,
  carrito,
  modificarCarritoState,
  headerState,
  errorState,
  modificarErrorState,
  recursosUrlState,
  actualizarSessionStorage,
  apiUrlState,
  spinnerState,
  modificarSpinnerState,
}) => {
  const { vendedor, metodoFact } = usePreventaContext();

  //Constantes a usar para el componente del modal
  const { isModalOpen, openModal, closeModal } = useModal();
  const [PedidoCorrecto, modificarPedidoCorrecto] = useState(null);
  //Cierra el modal desplegado
  const cerrarModal = () => {
    modificarErrorState({ Error: false, Mensaje: "" });
  };

  const getDataCart = () => {
    let value = 0, num_items = 0;
    let content_ids = [], contents = []
    for(const item of carrito["Carrito"]) {
      const id = item.Codigo;
      const quantity = item.Cantidad;
      const item_price = headerState["Cliente"] == "Mayoristas"
        ? item.PrecioMayorista
        : item.PrecioMinorista;
      value += item_price;
      num_items += quantity;
      content_ids.push(id);
      contents.push({id, quantity, item_price});
    }
    const custom_data = {
      currency: "COP",
      value,
      content_ids,
      contents,
      num_items
    }
    return custom_data;
  }
  
   /** Envia el evento Comprar de la pagina web a facebook */
   const enviarEventoFacebook = async () => {
    const event_id = actualizarEventId();
    const userIP = await apiFacebook.get();
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEventData`, {
      event_id: `prch-${event_id}`,
      event_name: "Purchase",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0",
      user_data: {
        email: clienteState.Correo,
        phone: clienteState.Celular,
        ge: clienteState.Genero,
        lastname: clienteState.Apellidos,
        name: clienteState.Nombres,
        city: clienteState.Ciudad,
        country: "co",
      },
      custom_data: getDataCart()
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  const CrearRemision = async (tipoCliente) => {
    let ciudad = await nombreCiudad();
    let linkPersonalizado;
    let pedido;
    switch (tipoCliente) {
      case "Contraentrega":
        linkPersonalizado = `https://api.whatsapp.com/send?phone=573016611801&text=Hola soy ${clienteState[
          "Nombres"
        ].slice(10)} ${clienteState["Apellidos"]} de la ciudad de ${
          ciudad[0]
        } (${ciudad[1]}) deseo recibir mi pedido contraentrega`;
        pedido = await crearPedido("Contraentrega", linkPersonalizado);
        break;
      case "Rappi":
        linkPersonalizado = `https://api.whatsapp.com/send?phone=573016611801&text=Hola soy ${clienteState[
          "Nombres"
        ].slice(10)} ${clienteState["Apellidos"]} de la ciudad de ${
          ciudad[0]
        } (${ciudad[1]}) deseo recibir mi pedido contraentrega Rappi`;
        pedido = await crearPedido("Rappi", linkPersonalizado);

        break;
      case "Consignacion":
        linkPersonalizado = `https://api.whatsapp.com/send?phone=573016611801&text=Hola soy ${clienteState["Nombres"]} ${clienteState["Apellidos"]} de la ciudad de ${ciudad[0]} (${ciudad[1]}) Realice mi compra por medio de consignacion`;
        pedido = await crearPedido("Consignacion", linkPersonalizado);

        break;

      default:
        cerrarModal();
        break;
    }
  };

  const listaCarrito = () => {
    let listaALlevar = [];
    for (let i = 0; i < carrito["Carrito"].length; i++) {
      // if (carrito["Carrito"][i]["id"].slice(-1) === "U") {
      if ( false ) {
        listaALlevar.push({
          _id: carrito["Carrito"][i]["_id"],
          Cantidad: carrito["Carrito"][i]["Cantidad"],
          Nombre: carrito["Carrito"][i]["Nombre"],
          Codigo: carrito["Carrito"][i]["Codigo"],
          Referencia: carrito["Carrito"][i]["Codigo"],
          PrecioMayorista: carrito["Carrito"][i]["PrecioMayorista"],
          PrecioMinorista: carrito["Carrito"][i]["PrecioMinorista"],
          Talla: carrito["Carrito"][i]["Talla"],
          DescuentoMayorista: carrito["Carrito"][i]["DescuentoMayorista"],
          DescuentoMinorista: carrito["Carrito"][i]["DescuentoMinorista"],
        });
      } else {
        listaALlevar.push({
          _id: carrito["Carrito"][i]["_id"],
          Cantidad: carrito["Carrito"][i]["Cantidad"],
          Nombre: carrito["Carrito"][i]["Nombre"],
          Codigo: carrito["Carrito"][i]["id"],
          Referencia: carrito["Carrito"][i]["Codigo"],
          Bodega: carrito["Carrito"][i]["Bodega"],
          PrecioMayorista: carrito["Carrito"][i]["PrecioMayorista"],
          PrecioMinorista: carrito["Carrito"][i]["PrecioMinorista"],
          Talla: carrito["Carrito"][i]["Talla"],
          DescuentoMayorista: carrito["Carrito"][i]["DescuentoMayorista"],
          DescuentoMinorista: carrito["Carrito"][i]["DescuentoMinorista"],
        });
      }
    }

    return listaALlevar;
  };

  //Limpia el state de carrito de todos los productos actuales.
  const vaciarCarrito = () => {
    modificarCarritoState({ Accion: "Activo", Carrito: [] });
    actualizarSessionStorage("Carrito", JSON.stringify([]));
  };
  const crearPedido = async (tipoPago, linkWhatsapp) => {
    let tipoPagoNormalizado = tipoPago === "Rappi" ? "Contraentrega" : tipoPago;
    TagManager.dataLayer({
      dataLayer: {
        event: "Carrito",
        path: "/Pedido-Final",
      },
    });

    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Creando pedido...",
      });
    }

    await axios
      .post(apiUrlState + "/Api/Contapyme/RealizarNuevaRemision", {
        Cliente: {
          NumeroDocumento: clienteState.NumeroDocumento,
          Nombres: clienteState.Nombres,
          Sucursal: clienteState.Sucursal,
          TipoCliente: headerState.Cliente,
          TipoPago: tipoPago,
        },
        Carrito: listaCarrito(),
        MedioDePago: tipoPago.toLowerCase(),
        Cupon: carrito.Cupon !== null ? carrito.Cupon : "",
        Banco: "",
        Vendedor: vendedor,
        Facturacion: metodoFact.Facturacion,
      })
      .then((response) => {
        if (!response.data.error) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          // ReactPixel.track("Finalizar Compra", "Purchase");
          enviarEventoFacebook();
          vaciarCarrito();
          modificarErrorState({
            Error: true,
            Mensaje: `Compra Separada ${tipoPagoNormalizado}`,
            link: linkWhatsapp,
          });

          modificarPedidoCorrecto(true);
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          modificarErrorState({
            Error: true,
            Mensaje: response.data.Mensaje,
            Referencias: response.data.ProductosNoPermitidos,
            tipoPago: tipoPago,
          });
        }
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (
          err.response.data.Mensaje ===
          "Se han agotado algunos productos de tu carrito."
        ) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.Mensaje,
            Referencias: err.response.data.ProductosNoPermitidos,
          });
        } else if (
          !err.response.data.Mensaje === "No es posible generar el pedido."
        ) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.Mensaje,
          });
        } else if (err.response.data.Mensaje !== undefined) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const formatNumber = (amount) => {
    let algo = Math.round(amount);
    var temp = algo + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };
  const nombreCiudad = async () => {
    let ciudad = clienteState["Ciudad"];
    let Departamento = clienteState["Departamento"];
    let ciudaR = await axios.post(apiUrlState + "/Api/Pedido/VerificarCiudad", [
      {
        _id: ciudad,
      },
      { Departamento: Departamento },
    ]);
    return [ciudaR.data[0].Nombre, ciudaR.data[0].Departamento.Nombre];
  };
  //Redirige a la vista /Mercado y se modifica el error State.
  const volverMercado = () => {
    if (headerState["Cliente"] === "Minoristas") window.location.href = "/";
    else if (headerState["Cliente"] === "Mayoristas" && vendedor) {
      window.location.href = "/Preventa/Catalogo";
    } else {
      window.location.href = "/Mayoristas";
    }
    modificarErrorState({ Error: false, Mensaje: "" });
  };
  const volver = () => {
    modificarClienteState({
      ...clienteState,
      Correo: "",
    });
    modificarErrorState({ Error: false, Mensaje: "" });
  };
  const informacionAdicional = async () => {
    let ciudad = await nombreCiudad();
    let linkPersonalizado = `https://api.whatsapp.com/send?phone=573016611801&text=Hola soy ${clienteState["Nombres"]} ${clienteState["Apellidos"]} de la ciudad de ${ciudad[0]} (${ciudad[1]}) Realice mi compra por medio de consignacion`;
    modificarErrorState({
      Error: true,
      Mensaje: `Compra Separada Consignacion`,
      link: linkPersonalizado,
    });
  };

  const informacionAdicionalPSE = async () => {
    let ciudad = await nombreCiudad();
    let linkPersonalizado = `https://api.whatsapp.com/send?phone=573016611801&text=Hola soy ${clienteState["Nombres"]} ${clienteState["Apellidos"]} de la ciudad de ${ciudad[0]} (${ciudad[1]}) Realice mi compra por medio de PSE`;
    modificarErrorState({
      Error: true,
      Mensaje: `Compra Separada PSE`,
      link: linkPersonalizado,
    });
  };

  const informacionAdicionalAddi = async () => {
    let ciudad = await nombreCiudad();
    let linkPersonalizado = `https://api.whatsapp.com/send?phone=573016611801&text=Hola soy ${clienteState["Nombres"]} ${clienteState["Apellidos"]} de la ciudad de ${ciudad[0]} (${ciudad[1]}) Realice mi compra por medio de ADDI`;
    modificarErrorState({
      Error: true,
      Mensaje: `Compra Separada ADDI`,
      link: linkPersonalizado,
    });
  };

  const volverMercadoAjustado = () => {
    AjustarPedido();
    if (headerState["Cliente"] === "Minoristas") window.location.href = "/";
    else window.location.href = "/Mayoristas";
    modificarErrorState({ Error: false, Mensaje: "" });
  };
  const AjustarPedido = async (tipopago) => {
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      for (var j = 0; j < errorState["Referencias"].length; j++) {
        if (
          carrito["Carrito"][i]["Codigo"] ===
          errorState["Referencias"][j]["Referencia"]
        ) {
          if (errorState["Referencias"][j]["Cantidad"] > 0) {
            disminuirCantidad(
              carrito["Carrito"][i],
              errorState["Referencias"][j]["Cantidad"]
            );
          } else {
            borrarElemento(carrito["Carrito"][i]);
          }
        }
      }
    }
    //window.location.href = '/Pedido'
    modificarErrorState({ Error: false, Mensaje: "" });
    if (headerState["Cliente"] === "Mayoristas") CrearRemision(tipopago);
  };

  const AjustarValores = async () => {
    let Nombre = clienteState["Nombres"].slice(10);
    clienteState["Nombres"] = Nombre;
    modificarClienteState({ ...clienteState, Nombres: Nombre });
    cerrarModal();
  };

  const calcularDescuento = (precio, descuento) => {
    const precioInt = parseInt(precio);
    const descuentoAplicar = parseInt(descuento);
    return precioInt - precioInt * (descuentoAplicar / 100);
  };

  //Disminuye la cantidad de un producto en el carrito en 1 segun la talla ingresada de este.
  //Actualiza el state del carrito y tambien el sesion storage.
  const disminuirCantidad = (producto, cantidad) => {
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (producto["id"] === carrito["Carrito"][i]["id"]) {
        if (carrito["Carrito"][i]["Cantidad"] - 1 === 0)
          carrito["Carrito"].splice(i, 1);
        else carrito["Carrito"][i]["Cantidad"] = cantidad;
      }
    }
    const carritoNew = [...carrito["Carrito"]];
    modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
    actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
  };

  //Elimina un producto del state del carrito.
  const borrarElemento = (producto) => {
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (producto["id"] === carrito["Carrito"][i]["id"]) {
        carrito["Carrito"].splice(i, 1);
      }
    }
    const carritoNew = [...carrito["Carrito"]];
    modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
    actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
  };

  //Esta funcion comprueba si existe la imagen en version jpg del producto, en caso que no, se lanza la imagen de respaldo general 'base'.
  const comprobarImgProducto = (codigo, talla) => {
    const imagen = document.getElementById(codigo + talla + "Pedido");
    let img = document.createElement("img");
    img.src = recursosUrlState + "/img/Productos/Ref " + codigo + ".webp";
    imagen.innerHTML =
      "<img class ='img-producto-carrito' src= '" +
      recursosUrlState +
      "/img/Productos/Ref " +
      codigo +
      ".webp' alt = '" +
      codigo +
      "' loading = 'lazy' />";
    img.onerror = function () {
      imagen.innerHTML =
        "<img class ='img-producto-carrito' src= '" +
        recursosUrlState +
        "/img/General/base.png' alt = '" +
        codigo +
        "' loading = 'lazy' />";
    };
  };

  //Si el modal esta cerrado y el errorState es true.
  //Si el modal esta abierto y el errorState es false.
  useEffect(() => {
    if (!isModalOpen && errorState["Error"]) openModal();
    else if (isModalOpen && !errorState["Error"]) closeModal();
  });

  return (
    <div
      style={{
        position: "relative",
        bottom: "50px !important",
        left: "50%",
        zIndex: 800,
      }}
    >
      <Modal
        id="any-unique-identifier"
        isOpen={isModalOpen}
        transition={ModalTransition.BOTTOM_UP}
      >
        {errorState["Mensaje"] === "Error al recuperar datos del servidor" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Discúlpanos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {errorState["Mensaje"]}
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => volverMercado()}
            >
              Volver Al Mercado
            </p>
          </div>
        ) : errorState["Mensaje"] ===
          "Talla no disponible para esta referencia." ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Discúlpanos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Actualmente no contamos con mas disponibilidad de tallas para esta
              referencia
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => volver()}
            >
              Volver Al Mercado
            </p>
          </div>
        ) : errorState["Mensaje"] === "Datos incompletos..." ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Verifica tus Datos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Datos incompletos o errados por favor verifíca el campo: <br></br>
              {errorState["faltante"]}
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Politica de datos" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Politica de tratamiento de datos personales y envio de publicidad
            </p>

            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Al realizar compras en nuestra tienda online, todo comprador
              autoriza el envió de información por medio de correo y mensajería
              de texto de parte de CROATTA. y dando cumplimiento de la Ley 1581
              de 2012, CROATTA Reconoce y protege el derecho que tienen todas
              las personas a conocer, actualizar y rectificar las informaciones
              que se hayan recogido sobre ellas en nuestras bases de datos.
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] ===
          "Este campo no puede contener menos de 6 digitos O mas de 13 digitos" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Verifica tus Datos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Este campo no puede contener menos de 6 digitos O mas de 13
              digitos.
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] ===
          "Este campo no puede contener letras o caracteres especiales, solo numeros" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Verifica tus Datos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Este campo no puede contener letras o caracteres especiales, solo
              numeros.
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] ===
          "Este campo no puede contener menos de 9 digitos" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Verifica tus Datos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Este campo no puede contener menos de 9 digitos.
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] === "La fecha ingresada es incorrecta" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Verifica tus Datos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              La fecha ingresada es incorrecta.
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Revisa Tus Datos." ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Verifica tus Datos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/arroba.png"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              El correo que has ingresado ya esta en uso por otro usuario.
            </p>
            <div>
              <div className="row justify-content-md-center">
                <div className="col-6">
                  <p
                    className="botonCerrarModal text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    onClick={() => volver()}
                  >
                    Volver
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] ===
          "Se han agotado algunos productos de tu carrito." ? (
          <div>
            <p className="FuenteBebas text-center" id="texto-Error-agotado">
              Algunos productos de tu carrito se han agotado.
            </p>
            <ul className="list-group">
              <div>
                {errorState["Referencias"].map((producto) => (
                  <div className="productosCarro">
                    <div className="row justify-content-md-center">
                      <div className="col-md-auto">
                        <img
                          className="img-producto-carrito"
                          src={
                            recursosUrlState +
                            "/img/Productos/Ref " +
                            producto["Referencia"] +
                            ".webp"
                          }
                          alt={producto["Referencia"]}
                          loading="lazy"
                          onError={(e) =>
                            comprobarImgProducto(
                              producto["Referencia"],
                              producto["Talla"]
                            )
                          }
                        />
                      </div>
                      <div className="col-3 textoCarrito text-left">
                        <p style={{ marginBottom: 0 }}>
                          Referencia: {producto["Codigo"]}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          Talla: {producto["Talla"]}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          Cantidad Disponible: {producto["Cantidad"]}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </ul>
            <p className="FuenteBebas text-center" id="texto-Error-agotado">
              Deseas continuar sin estas referencias.
            </p>
            <div>
              <div className="productosCarro">
                <div className="row justify-content-md-center">
                  <div className="col-6">
                    <p
                      className="botonCerrarModal text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => volverMercadoAjustado()}
                    >
                      NO
                    </p>
                  </div>
                  <div className="col-6">
                    <p
                      className="botonCerrarModal text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => AjustarPedido(errorState["tipoPago"])}
                    >
                      SI
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "No es posible generar el pedido." ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Discúlpanos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <ul className="list-group">
              {errorState["Referencias"].map((Referencia) => (
                <li
                  className="list-group-item FuenteHelvetica text-center"
                  id="texto-Error"
                  style={{ fontSize: "1.2em", marginTop: "0px" }}
                >
                  {Referencia}
                </li>
              ))}

              <p
                className="botonCerrarModal text-center FuenteBebas"
                style={{ cursor: "pointer" }}
                onClick={() => AjustarPedido("")}
              >
                Ajustar Pedido
              </p>
            </ul>
          </div>
        ) : errorState["Mensaje"] === "Consignacion Bancolombia" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              BANCOLOMBIA
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = recursosUrlState + "/img/Modal/pin-negro.png";
              }}
            />
            <p
              className="FuenteHelvetica text-center"
              style={{ fontSize: "1.2em" }}
            >
              Hola {clienteState["Nombres"]}, paga tu pedido en Bancolombia por
              medio de una transferencia Electrónica, consignación mediante un
              corresponsal bancario o PAC. No directamente en un banco para
              evitar el cobro de la transacción que será cargado a tu pedido.
            </p>
            <p
              className="FuenteHelvetica  text-center"
              id="texto-Error"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Nro Cuenta: 76271246338.<br></br>
              Tipo de cuenta: Ahorros. <br></br> <br></br>
              El total a pagar seria $ {formatNumber(errorState["Total"])}{" "}
              pesos.
            </p>

            <p className="FuenteHelvetica text-center">
              * El valor del envió lo cancelaras cuando recibas tu pedido.
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => CrearRemision("Consignacion")}
            >
              Aceptar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Consignacion Efecty" ||
          errorState["Mensaje"] === "Consignacion SuperGiros" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              {errorState["Mensaje"].slice(13)}
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = recursosUrlState + "/img/Modal/pin-negro.png";
              }}
            />
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Hola {clienteState["Nombres"]} para el pago por&nbsp;
              {errorState["Mensaje"].slice(13)}, debes tener presente que el
              valor del envió de dinero corre por tu cuenta, a nosotros debe
              llegarnos el valor total del pedido.
            </p>
            <p
              className="FuenteHelvetica textoPaso2 text-center"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Jose David Victoria <br></br>
              CC: 1116250415.<br></br>
              Tuluá - Valle del cauca.<br></br> <br></br>
              el total a pagar seria $ {formatNumber(errorState["Total"])}{" "}
              Pesos.
            </p>

            <p className="FuenteHelvetica text-center">
              * El valor del envió lo cancelaras cuando recibas tu pedido.
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => CrearRemision("Consignacion")}
            >
              Aceptar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Envio Rappi" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Envio con Recaudo
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = recursosUrlState + "/img/Modal/pin-negro.png";
              }}
            />

            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Hola {clienteState["Nombres"].slice(10)} te enviamos tu pedido con
              Rappi el cual tiene un costo promedio entre{" "}
              {errorState["EnvioRR"]}.
            </p>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              el total a pagar seria $ {formatNumber(errorState["Total"])}{" "}
              Pesos.
            </p>
            <p className="FuenteHelvetica text-center">
              * El valor del envió lo cancelaras cuando recibas tu pedido.
            </p>

            <p className="FuenteBebas text-center" id="texto-Error-agotado">
              ¿Te lo enviamos?
            </p>
            <div>
              <div className="productosCarro">
                <div className="row justify-content-md-center">
                  <div className="col-6">
                    <p
                      className="botonCerrarModal text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => AjustarValores()}
                    >
                      NO
                    </p>
                  </div>
                  <div className="col-6">
                    <p
                      className="botonCerrarModal text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => CrearRemision("Rappi")}
                    >
                      SI
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "Ciudad Disponible para RR" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Envio con Recaudo
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = recursosUrlState + "/img/Modal/pin-negro.png";
              }}
            />

            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Hola {clienteState["Nombres"].slice(10)} ten en cuenta que este
              servicio tiene un costo adicional de $
              {formatNumber(errorState["EnvioRR"])}
              &nbsp;pesos, este valor es lo que cobraría la trasportadora por
              recaudar tu dinero y enviarlo a nosotros.
            </p>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              El total a pagar seria
              <b> $ {formatNumber(errorState["Total"])} Pesos.</b>
            </p>
            <p className="FuenteHelvetica text-center">
              * El valor del envió lo cancelaras cuando recibas tu pedido.
            </p>
            <p className="FuenteBebas text-center" id="texto-Error-agotado">
              ¿Te lo enviamos?
            </p>
            <div>
              <div className="productosCarro">
                <div className="row justify-content-center">
                  <div className="col-6">
                    <p
                      className="botonCerrarModal text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => AjustarValores()}
                    >
                      NO
                    </p>
                  </div>
                  <div className="col-6">
                    <p
                      className="botonCerrarModal text-center FuenteBebas"
                      style={{ cursor: "pointer" }}
                      onClick={() => CrearRemision("Contraentrega")}
                    >
                      SI
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "Ciudad NO disponible para RR" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Discúlpanos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/store-slash-solid.svg"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Lo sentimos {clienteState["Nombres"]} no contamos con este
              servicio para tu ciudad.
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Monto NO disponible para RR" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Lo sentimos
            </p>
            <img
              src={recursosUrlState + "/img/Modal/store-slash-solid.svg"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Por políticas internas de la
              transportadora el monto máximo para pedidos contra entrega es de
              $2.000.000 COP.
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Compra Separada Consignacion" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/cart-plus-solid.svg"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"]} Recuerda que tienes 48 Horas a partir de
              este momento para realizar el pago o tu pedido sera eliminado
              automaticamente.
            </p>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              Cuando realices el pago envíanos el comprobante por WhatsApp al
              siguiente número <b>+57 301 661 1801</b> o haz click en el
              siguiente <a href={errorState["link"]}> enlace a WhatsApp</a>
            </p>
            <div className="productosCarro">
              <div className="row justify-content-center">
                <div className="col-6">
                  <p
                    className="botonCerrarModal text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    onClick={() => volverMercado()}
                  >
                    Volver Al Mercado
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="FuenteBebas botonCerrarModal text-center"
                    onClick={() => (window.location.href = errorState["link"])}
                    style={{ cursor: "pointer" }}
                  >
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "Compra Separada PSE" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/cart-plus-solid.svg"}
              className="img-Modal"
              alt="ModalError"
            ></img>

            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"]} Cuando realices el pago envíanos el
              comprobante por WhatsApp al siguiente número{" "}
              <b>+57 301 661 1801</b> o haz click en el siguiente{" "}
              <a href={errorState["link"]}> enlace a WhatsApp</a>
            </p>
            <div className="productosCarro">
              <div className="row justify-content-center">
                <div className="col-6">
                  <p
                    className="botonCerrarModal text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    onClick={() => volverMercado()}
                  >
                    Volver Al Mercado
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="FuenteBebas botonCerrarModal text-center"
                    onClick={() => (window.location.href = errorState["link"])}
                    style={{ cursor: "pointer" }}
                  >
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "Compra Separada ADDI" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/cart-plus-solid.svg"}
              className="img-Modal"
              alt="ModalError"
            ></img>

            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"]} Cuando realices el pago envíanos el
              comprobante por WhatsApp al siguiente número{" "}
              <b>+57 301 661 1801</b> o haz click en el siguiente{" "}
              <a href={errorState["link"]}> enlace a WhatsApp</a>
            </p>
            <div className="productosCarro">
              <div className="row justify-content-center">
                <div className="col-6">
                  <p
                    className="botonCerrarModal text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    onClick={() => volverMercado()}
                  >
                    Volver Al Mercado
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="FuenteBebas botonCerrarModal text-center"
                    onClick={() => (window.location.href = errorState["link"])}
                    style={{ cursor: "pointer" }}
                  >
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "Compra Separada Contraentrega" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/cart-plus-solid.svg"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"].slice(10)} Confirmanos que deseas recibir
              tu compra con pago contraentrega.Escribenos por WhatsApp al
              siguiente número <b>+57 301 661 1801</b> o haz click en el
              siguiente <a href={errorState["link"]}> enlace a WhatsApp</a>
            </p>
            <div className="productosCarro">
              <div className="row justify-content-center">
                <div className="col-6">
                  <p
                    className="botonCerrarModal text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    onClick={() => volverMercado()}
                  >
                    Volver Al Mercado
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="FuenteBebas botonCerrarModal text-center"
                    onClick={() => (window.location.href = errorState["link"])}
                    style={{ cursor: "pointer" }}
                  >
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] === "Tu cupon se canjeo correctamente" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Cupón Validado
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {errorState["Cliente"].split("-")[1]} tu cupón de{" "}
              {errorState["Descripcion"].toLowerCase()} se valido correctamente
              <br />
              {errorState["Tipo"] === "DESCUENTO"
                ? "el total de tu compra tendra un " +
                  errorState["valor"] +
                  "% de descuento."
                : "el total de tu compra tendra un descuento de $" +
                  errorState["valor"] +
                  " pesos."}
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Aceptar
            </p>
          </div>
        ) : errorState["Mensaje"] === "Tu cupon es invalido o ha expirado" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Cupón Validado
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {errorState["Cliente"]} lo sentimos tu cupón es invalido o ya ha
              vencido.
              <br />
              Escríbenos para validar la información.
            </p>

            <div className="productosCarro">
              <div className="row justify-content-center">
                <div className="col-6">
                  <p
                    className="botonCerrarModal text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    onClick={() => cerrarModal()}
                  >
                    Cerrar
                  </p>
                </div>
                <div className="col-6">
                  <p
                    className="FuenteBebas botonCerrarModal text-center"
                    onClick={() =>
                      (window.location.href =
                        "https://api.whatsapp.com/send?phone=573016611801&text=Hola Croatta soy " +
                        clienteState["Nombres"] +
                        " " +
                        clienteState["Apellidos"] +
                        " estoy teniendo problemas para canjear un cupon.")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : errorState["Mensaje"] ===
          "Te redireccionamos a la ventana de pago." ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"]} Te redireccionamos a la ventana de pago
              PSE.<br></br>
              Recuerda permitir las ventanas emergentes y redirecciones en tu
              navegador para esta pagina.
              <br></br> El total a pagar seria
              <b> $ {formatNumber(errorState["Total"])} Pesos.</b>
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => informacionAdicionalPSE()}
            >
              Aceptar
            </p>
          </div>
        ) : errorState["Mensaje"] ===
          "Te redireccionamos a la ventana de pago." ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"]} Te redireccionamos a la ventana de pago
              PSE.<br></br>
              Recuerda permitir las ventanas emergentes y redirecciones en tu
              navegador para esta pagina.
              <br></br> El total a pagar seria
              <b> $ {formatNumber(errorState["Total"])} Pesos.</b>
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => informacionAdicionalPSE()}
            >
              Aceptar
            </p>
          </div>
        ) : errorState["Mensaje"] ===
        "Te redireccionamos a la ventana de pago Addi." ? (
        <div>
          <p className="FuenteBebas text-center" id="TituloError">
            Hemos Separado Tu Compra
          </p>
          <img
            src={recursosUrlState + "/img/Modal/pin-negro.webp"}
            className="img-Modal"
            alt="ModalError"
          ></img>
          <p
            className="FuenteHelvetica text-center"
            id="texto-Error"
            style={{ fontSize: "1.2em" }}
          >
            {clienteState["Nombres"]} Te redireccionamos a la ventana de pago
            ADDI.<br></br>
            Recuerda regresar a esta pestaña al realizar el pago para continuar con el proceso.
            <br></br>
            Cuando realices el pago puedes dar clic en Aceptar.
            <br></br> El total a pagar sería
            <b> $ {formatNumber(errorState["Total"])} Pesos.</b>
          </p>
          <a 
            href={errorState["Link"]} target="_blank" rel="noopener noreferrer">
            <p className="botonCerrarModal text-center FuenteBebas">
              Pagar con ADDI
            </p>
          </a>

          <p
            className="botonCerrarModal text-center FuenteBebas"
            style={{ cursor: "pointer" }}
            onClick={() => informacionAdicionalAddi()}
          >
            Aceptar
          </p>
        </div>
      ) : errorState["Mensaje"] === "Registro exitoso" ? (
        <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Tu registro fue exitoso
            </p>
            <img
              src={recursosUrlState + "/img/Modal/awards.png"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.5em" }}
            >
              Gracias por tu participación.<br></br>
              Esperamos ver todo tu talento.<br></br>
              ¡Muchos éxitos!.
            </p>
            <a href="https://www.croatta.com">
              <p
                className="botonCerrarModal text-center FuenteBebas"
                style={{ cursor: "pointer" }}
                // onClick={() => }
              >
                Aceptar
              </p>
            </a>
          </div>
      ) : errorState["Mensaje"] === "Bienvenido Vendedor" ? (
        <div>
            <p className="FuenteBebas text-center" id="TituloError"
              style={{marginTop:"0.5em"}}
            >
              PREVENTA MERCADER
            </p>
            <img
              src={recursosUrlState + "/img/Modal/handshake.png"}
              className="img-Modal"
              alt="Bienvenida"
            ></img>
            <p className="FuenteBebas text-center" id="TituloError"
              style={{marginTop:"1.5em"}}
            >
              BIENVENID@ {errorState["Vendedor"]}
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Aceptar
            </p>
          </div>
      ) : errorState["Mensaje"] === "este es tu pin" ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Hemos Separado Tu Compra
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {clienteState["Nombres"]} tu pin de pago {errorState["MedioPago"]}{" "}
              es:
              <br></br>
              {errorState["pin"]}
              <br></br> El total a pagar seria
              <b> $ {formatNumber(errorState["Total"])} Pesos.</b>
            </p>

            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => informacionAdicionalPSE()}
            >
              Aceptar
            </p>
          </div>
        ) : errorState["Mensaje"].indexOf("Este es tu pin") < 0 &&
          errorState["Mensaje"].indexOf(
            "Te redireccionamos a la ventana de pago."
          ) < 0 &&
          errorState["Mensaje"].indexOf("Hemos separado tu compra") < 0 &&
          errorState["Mensaje"].indexOf("Tu cupon se canjeo correctamente") <
            0 &&
          errorState["Mensaje"].indexOf("Tu cupon es invalido o ha expirado") <
            0 ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Vaya..
            </p>
            <img
              src={recursosUrlState + "/img/Modal/error.gif"}
              className="img-Modal"
              alt="ModalError"
            ></img>
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {errorState["Mensaje"]}
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Cerrar
            </p>
          </div>
        ) : errorState["Mensaje"].indexOf("Tu cupon se canjeo correctamente") <
            0 &&
          errorState["Mensaje"].indexOf("Tu cupon es invalido o ha expirado") <
            0 ? (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Pedido Separado
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = recursosUrlState + "/img/Modal/pin-negro.png";
              }}
            />

            <div className="">
              <div className="row justify-content-md-center">
                <p
                  className="FuenteHelvetica text-center"
                  id="texto-Error"
                  style={{ fontSize: "1.2em" }}
                >
                  {errorState["Mensaje"]}
                </p>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-md-auto">
                  <p
                    className="FuenteHelvetica text-center"
                    id="texto-Error"
                    style={{ fontSize: "1.2em" }}
                  >
                    Total a Pagar
                  </p>
                </div>
                <div className="col-md-auto">
                  <p
                    className="FuenteHelvetica text-center"
                    id="texto-Error"
                    style={{ fontSize: "1.2em" }}
                  >
                    {formatNumber(errorState["Total"])}
                  </p>
                </div>
              </div>
            </div>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => volverMercado()}
            >
              Volver a la tienda
            </p>
          </div>
        ) : (
          <div>
            <p className="FuenteBebas text-center" id="TituloError">
              Informacion cupo
            </p>
            <img
              src={recursosUrlState + "/img/Modal/pin-negro.webp"}
              className="img-Modal"
              alt="ModalError"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = recursosUrlState + "/img/Modal/pin-negro.png";
              }}
            />
            <p
              className="FuenteHelvetica text-center"
              id="texto-Error"
              style={{ fontSize: "1.2em" }}
            >
              {errorState["Mensaje"]} few
            </p>
            <p
              className="botonCerrarModal text-center FuenteBebas"
              style={{ cursor: "pointer" }}
              onClick={() => cerrarModal()}
            >
              Aceptar
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalGeneral;
