//Zona de importacion de componentes externos.
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
//Zona de importacion de componentes internos.
import ListaProductos from "./ListaProductos";
import { useParams } from "react-router";
import Footer from "../Footer/Footer";
import CarouselInfo from "../Index/CarouselInfo";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";
import { actualizarEventId } from "../../global/functions";

import "./static/css/Mayoristas/Mayoristas.css";

const Mayoristas = ({
  ViewDetalle,
  headerState,
  modificarHeaderState,
  filtrosState,
  modificarFiltrosState,
  productosState,
  modificarProductosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  carrito,
  modificarCarritoState,
  cliente,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  clienteState,
  modificarClienteState,
  categoriasHombreState,
  modificarCategoriasHombreState,
  categoriasMujerState,
  modificarCategoriasMujerState,
  CatalogoState,
  actualizarSessionStorage,
  rutaDinamica,
}) => {

  // ReactPixel.track("HomeMayoristas", "ViewContent");
  /** Envia el evento de visita a la pagina web de mayoristas a facebook */
  const enviarEventoFacebook = async () => {
    const event_id = actualizarEventId();
    const userIP = await apiFacebook.get();
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `hmay-${event_id}`,
      event_name: "HomeMayoristas",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0"
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  let { Categoria, Genero, Subgenero, Marca } = useParams();
  //Referencia para el primer producto.
  const barCamisetasHombre = useRef(null);
  const barCamisetasHombreBasicas = useRef(null);
  const barCamisetasHombreEstampadas = useRef(null);
  const barCamisetasHombreValtimor = useRef(null);
  const barHoodiesHombre = useRef(null);
  const barJoggersHombre = useRef(null);
  const barPantalonetasHombre = useRef(null);
  const barPolosHombre = useRef(null);
  const barGorras = useRef(null);
  const barCamisetasMujer = useRef(null);
  const barCamisetasBasicasMujer = useRef(null);
  const barHoodiesMujer = useRef(null);
  const barJoggersMujer = useRef(null);
  const barBlusas = useRef(null);
  const barVestidos = useRef(null);
  const barLycras = useRef(null);
  const barBodys = useRef(null);
  const barCamisas = useRef(null);
  const barPantalones = useRef(null);
  const barDescuentos = useRef(null);

  // Creamos un state para cada Bar de categoria.
  const [estadoBar, actualizarEstadoBar] = useState({
    listaCamisetasHombre: false,
    listaCamisetasHombreBasicas: false,
    listaCamisetasHombreEstampadas: false,
    listaCamisetasHombreValtimor: false,
    listaHoodiesHombre: false,
    listaJoggersHombre: false,
    listaPantalonetasHombre: false,
    listaPolosHombre: false,
    listaGorras: false,
    listaCamisetasMujer: false,
    listaCamisetasBasicasMujer: false,
    listaHoodiesMujer: false,
    listaJoggersMujer: false,
    listaBlusas: false,
    listaVestidos: false,
    listaLycras: false,
    listaBodys: false,
    listaCamisas: false,
    listaPantalones: false,
    listaDescuentos: false,
  });

  const [data, actualizarData] = useState({
    GORRAS: { Nuevo: false, Descuento: 0, Disponible: true },
    BASICAS: { Nuevo: false, Descuento: 0, Disponible: true },
    ESTAMPADAS: { Nuevo: false, Descuento: 0, Disponible: true },
    VALTIMOR: { Nuevo: false, Descuento: 0, Disponible: true },
    MUJER: { Nuevo: false, Descuento: 0, Disponible: true },
    CB_MUJER: { Nuevo: false, Descuento: 0, Disponible: true },
    POLOS: { Nuevo: false, Descuento: 0, Disponible: true },
    BLUSAS: { Nuevo: false, Descuento: 0, Disponible: true },
    VESTIDOS: { Nuevo: false, Descuento: 0, Disponible: true },
    LYCRAS: { Nuevo: false, Descuento: 0, Disponible: true },
    BODYS: { Nuevo: false, Descuento: 0, Disponible: true },
    CAMISAS: { Nuevo: false, Descuento: 0, Disponible: true },
    PANTALONETAS: { Nuevo: false, Descuento: 0, Disponible: true },
    HOODIES_H: { Nuevo: false, Descuento: 0, Disponible: true },
    HODDIES_M: { Nuevo: false, Descuento: 0, Disponible: true },
    JOGGER_H: { Nuevo: false, Descuento: 0, Disponible: true },
    JOGGER_M: { Nuevo: false, Descuento: 0, Disponible: true },
    PANTALONES_H: { Nuevo: false, Descuento: 0, Disponible: true },
  });

  //Se hace una consulta a la api para obtener los productos segun la seccion ingresada por parametro en la ruta '/Inventario/ConsultarProductos/{Categoria}/{Genero}'
  const obtenerProductos = async (
    refBar,
    categoriaId,
    generoId,
    marcaId,
    subgeneroId,
    marcaExcluida
  ) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }
    let url = apiUrlState + "/Api/Producto/GetProductos";
    let data = {
      Categorias: [],
      Generos: [],
      Marcas: [],
      Medidas: [],
      Tallas: [],
      Colores: [],
      Subcategoria: [],
      ParametroOrdenamiento: "",
      ProductosNuevos: false,
      Descuentos: false,
      Todo: false,
    };
    let i;
    const categoriaALlamar = categoriaId;
    const marcaALlamar = marcaId;
    const SubgeneroALlamar = subgeneroId;
    const MarcaExcluida = marcaExcluida;
    if (categoriaALlamar === "Descuentos") {
      data["Descuentos"] = true;
    } else if (categoriaALlamar !== null) {
      if (MarcaExcluida === "607ed64c521b1c0910a581b3") {
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b4" });
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b5" });
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b6" });
        data["Marcas"].push({ Marca: "607ed64c521b1c0910a581b7" });
      }
      if (marcaALlamar !== null) {
        data["Marcas"].push({ Marca: marcaALlamar });
      }
      if (SubgeneroALlamar !== null) {
        data["Subcategoria"].push({ Subcategoria: SubgeneroALlamar });
      }
      // data["Categorias"].push({ Categoria: categoriaALlamar });
      // Mostrar blusas dk en categoria camiseta mujer
      if (categoriaALlamar === "607ed63e521b1c0910a581aa" && generoId === "607ed653521b1c0910a581b9" ){
        data["Categorias"].push({ Categoria: categoriaALlamar });
        // data["Categorias"].push({ Categoria: "651aec4251072238a0969749" });
      } else {
        data["Categorias"].push({ Categoria: categoriaALlamar });
      }
      // end mostrar blusas dk en categoria camiseta mujer
      if (categoriaALlamar !== "607ed63e521b1c0910a581ac")
        data["Generos"].push({ Genero: generoId });
    } else data["ProductosNuevos"] = true;

    for (i = 0; i < marcasState.length; i++) {
      data["Marcas"].push({ Marca: marcasState[i] });
    }
    for (i = 0; i < tallasState.length; i++) {
      data["Tallas"].push({ "Variantes.Tallas.Talla": tallasState[i] });
    }
    for (i = 0; i < coloresState.length; i++) {
      data["Colores"].push({ "Variantes.Color": coloresState[i] });
    }
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }

    await axios
      .post(
        url,
        {
          data,
        },
        {}
      )
      .then((response) => {
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
        //refBar.current.scrollIntoView({behavior: "smooth"});
      })
      .catch(function (err) {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: false },
          [],
        ]);
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        }
      });
  };

  const obtenerData = async () => {
    let url = apiUrlState + "/Api/Producto/getData";
    await axios.get(url).then((response) => {
      actualizarData(response.data[0]);
    });
  };
  //Limpia los states de todos los filtros para no dejar ninguno seleccionado.
  const limpiarFiltros = () => {
    if (tallasState.length > 0) modificarTallasState([]);
    if (coloresState.length > 0) modificarColoresState([]);
    if (marcasState.length > 0) modificarMarcasState([]);
  };

  //Funcion para actualizar el estado de cada lista de productos.
  const actualizarLista = (
    queBar,
    categoriaId,
    generoId,
    refBar,
    MarcaId,
    SubgeneroId,
    MarcaExcluida
  ) => {
    if (queBar === "listaGorras") {
      ReactPixel.track("Gorras", "ViewContent");
    }
    if (queBar === "listaPolosHombre") {
      ReactPixel.track("Polos", "ViewContent");
    }
    if (queBar === "listaCamisetasMujer") {
      ReactPixel.track("Camisetas Mujer", "ViewContent");
    }
    if (queBar === "listaCamisetasBasicasMujer") {
      ReactPixel.track("Camisetas Basicas Mujer", "ViewContent");
    }
    if (queBar === "listaBlusas") {
      ReactPixel.track("Blusas Basicas", "ViewContent");
    }
    if (queBar === "listaHoodiesHombre") {
      ReactPixel.track("Hoodies Hombre", "ViewContent");
    }
    if (queBar === "listaHoodiesMujer") {
      ReactPixel.track("Hoodies Mujer", "ViewContent");
    }
    if (queBar === "listaPantalonetasHombre") {
      ReactPixel.track("Pantalonetas", "ViewContent");
    }
    if (queBar === "listaJoggersHombre") {
      ReactPixel.track("Joggers Hombre", "ViewContent");
    }
    if (queBar === "listaJoggersMujer") {
      ReactPixel.track("Joggers Mujer", "ViewContent");
    }
    if (queBar === "listaCamisetasHombreBasicas") {
      ReactPixel.track("Camisetas Basicas", "ViewContent");
    }
    if (queBar === "listaCamisetasHombreEstampadas") {
      ReactPixel.track("Camisetas Estampadas", "ViewContent");
    }
    if (queBar === "listaCamisetasHombreValtimor") {
      ReactPixel.track("Camisetas Valtimor", "ViewContent");
    }
    if (queBar === "listaPantalones") {
      ReactPixel.track("Pantalones Cargo", "ViewContent");
    }

    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    limpiarFiltros();
    if (!estadoBar[queBar]) {
      obtenerProductos(
        refBar,
        categoriaId,
        generoId,
        MarcaId,
        SubgeneroId,
        MarcaExcluida
      );
    }
    let estados = {
      listaCamisetasHombre: false,
      listaCamisetasHombreBasicas: false,
      listaCamisetasHombreEstampadas: false,
      listaCamisetasHombreValtimor: false,
      listaHoodiesHombre: false,
      listaJoggersHombre: false,
      listaPantalonetasHombre: false,
      listaPolosHombre: false,
      listaGorras: false,
      listaCamisetasMujer: false,
      listaCamisetasBasicasMujer: false,
      listaHoodiesMujer: false,
      listaJoggersMujer: false,
      listaBlusas: false,
      listaVestidos: false,
      listaLycras: false,
      listaBodys: false,
      listaCamisas: false,
      listaPantalones: false,
      listaDescuentos: false,
    };
    estados[queBar] = !estadoBar[queBar];
    actualizarEstadoBar(estados);
  };

  //Ruta landing
  const RutaLanding = async () => {
    //rutaDinamica = false

    if (Categoria === "Camiseta" && Genero === "Hombre") {
      actualizarLista(
        "listaCamisetasHombre",
        "607ed63e521b1c0910a581aa",
        "607ed653521b1c0910a581b8",
        "Hombre",
        null,
        null,
        null,
        barCamisetasHombre
      );
    }
    if (Categoria === "Camiseta" && Genero === "Mujer") {
      actualizarLista(
        "listaCamisetasMujer",
        "607ed63e521b1c0910a581aa",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barCamisetasMujer
      );
    }
    if (Categoria === "Gorra" && Genero === "Unisex") {
      actualizarLista(
        "listaGorras",
        "607ed63e521b1c0910a581ac",
        "607ed653521b1c0910a581bb",
        "Hombre",
        null,
        null,
        null,
        barGorras
      );
    }
    if (Categoria === "Hoodie" && Genero === "Hombre") {
      actualizarLista(
        "listaHoodiesHombre",
        "607ed63e521b1c0910a581ad",
        "607ed653521b1c0910a581b8",
        "Hombre",
        null,
        null,
        null,
        barHoodiesHombre
      );
    }
    if (Categoria === "Hoodie" && Genero === "Mujer") {
      actualizarLista(
        "listaHoodiesMujer",
        "607ed63e521b1c0910a581ad",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barHoodiesMujer
      );
    }
    if (Categoria === "Polo" && Genero === "Hombre") {
      actualizarLista(
        "listaPolosHombre",
        "607ed63f521b1c0910a581b1",
        "607ed653521b1c0910a581b8",
        "Hombre",
        null,
        null,
        null,
        barPolosHombre
      );
    }
    if (Categoria === "Blusa") {
      actualizarLista(
        "listaBlusas",
        "651aec4251072238a0969749",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barBlusas
      );
    }
    if (Categoria === "Vestido") {
      actualizarLista(
        "listaVestidos",
        "65578d4701bf5211b63d14bc",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barVestidos
      );
    }
    if (Categoria === "Lycra") {
      actualizarLista(
        "listaLycras",
        "6525a7906b44bb8bf3323210",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barLycras
      );
    }
    if (Categoria === "Body") {
      actualizarLista(
        "listaBodys",
        "655bad5a1cdc932b9e974a01",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barBodys
      );
    }
    if (Categoria === "Camisa") {
      actualizarLista(
        "listaCamisas",
        "655bac441cdc932b9e9749ff",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barCamisas
      );
    }
    if (Categoria === "Pantaloneta" && Genero === "Hombre") {
      actualizarLista(
        "listaPantalonetasHombre",
        "607ed63e521b1c0910a581b0",
        "607ed653521b1c0910a581b8",
        "Hombre",
        null,
        null,
        null,
        barPantalonetasHombre
      );
    }
    if (Categoria === "Jogger" && Genero === "Hombre") {
      actualizarLista(
        "listaJoggersHombre",
        "607ed63e521b1c0910a581af",
        "607ed653521b1c0910a581b8",
        "Hombre",
        null,
        null,
        null,
        barJoggersHombre
      );
    }
    if (Categoria === "Jogger" && Genero === "Mujer") {
      actualizarLista(
        "listaJoggersMujer",
        "607ed63e521b1c0910a581af",
        "607ed653521b1c0910a581b9",
        "Mujer",
        null,
        null,
        null,
        barJoggersMujer
      );
    }
    if (
      Categoria === "Camiseta" &&
      Genero === "Hombre" &&
      Marca === "Valtimor"
    ) {
      actualizarLista(
        "listaCamisetasHombreValtimor",
        "607ed63e521b1c0910a581aa",
        "607ed653521b1c0910a581b8",
        "Hombre",
        null,
        null,
        "607ed64c521b1c0910a581b3",
        barCamisetasHombreValtimor
      );
    }
    if (
      Categoria === "Camiseta" &&
      Genero === "Hombre" &&
      Subgenero === "Estampadas"
    ) {
      actualizarLista(
        "listaCamisetasHombreEstampadas",
        "607ed63e521b1c0910a581aa",
        "607ed653521b1c0910a581b8",
        "Hombre",
        "607ed64c521b1c0910a581b3",
        "607ed65a521b1c0910a581bf",
        null,
        null,
        barCamisetasHombreEstampadas
      );
    }
    if (
      Categoria === "Camiseta" &&
      Genero === "Hombre" &&
      Subgenero === "Basicas"
    ) {
      actualizarLista(
        "listaCamisetasHombreBasicas",
        "607ed63e521b1c0910a581aa",
        "607ed653521b1c0910a581b8",
        "Hombre",
        "607ed64c521b1c0910a581b3",
        "607ed65a521b1c0910a581bd",
        null,
        barCamisetasHombreBasicas
      );
    }
    //actualizarEstadoBar(estados);
  };

  useEffect(() => {
    var header = JSON.parse(JSON.stringify(headerState));

    if (headerState["displayMenu"] === "Index") {
      modificarFiltrosState({
        Genero: filtrosState["Genero"],
        Categoria: filtrosState["Categoria"],
        CambioFiltro: false,
        CambioCategoria: false,
        filtroResumido: false,
        posicionFiltros: "-100%",
      });
      const tipoClienteSession = sessionStorage.TipoCliente;
      modificarHeaderState({
        srcLogo: headerState["srcLogo"],
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha: headerState["srcFlecha"],
        displayBuscar: "in-line",
        displayMenu: "Productos",
        color: "negro",
        Seccion: headerState["Seccion"],
        Cliente: tipoClienteSession,
        BotonCatalogo: CatalogoState,
      });
    }
    if (headerState["Cliente"] === undefined) {
      modificarHeaderState({
        srcLogo: headerState["srcLogo"],
        srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
        srcFlecha: headerState["srcFlecha"],
        displayBuscar: "in-line",
        displayMenu: "Productos",
        color: "negro",
        Seccion: headerState["Seccion"],
        Cliente: "Mayoristas",
        BotonCatalogo: CatalogoState,
      });
      actualizarSessionStorage("TipoCliente", "Mayoristas");
      if (header["displayBuscar"] !== "in-line") {
        header["displayBuscar"] = "in-line";
        modificarHeaderState(header);
      }
      RutaLanding();
    }
    if (clienteState["Registro"]) {
      actualizarSessionStorage(
        "Cliente",
        JSON.stringify({
          ...clienteState,
          Registro: false,
        })
      );
      modificarClienteState({
        ...clienteState,
        Registro: false,
      });
    }
  });
  useEffect(() => {
    textoCirculo();
    obtenerData();
  }, []);

  useEffect(() => {
    enviarEventoFacebook();
   }, [])

  const textoCirculo = () => {
    const circulo = document.getElementById("circle");
    const circleArray = circulo.textContent.trimStart().split("");
    circulo.textContent = " ";
    for (var i = 0; i < circleArray.length; i++) {
      circulo.innerHTML +=
        '<span style="transform:rotate(' +
        (i - 8) * 12 +
        'deg)">' +
        circleArray[i] +
        "</span>";
    }
  };

  const images = {
    img1: [
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL1.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL2.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL3.webp",
    ],
    img2: [
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL21.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL22.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL23.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL24.webp",
    ],
    img3: [
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL31.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL32.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL33.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL34.webp",
    ]

  }
  /*
    //Mediante la referenca del primer producto se desplaza el scroll de forma suave hacia el.
    const irInicio = () => {
        if(prod1["current"] !== null){
            prod1.current.scrollIntoView({behavior: "smooth"});
            modificarProductosState([{"CambioSeccion": false, "Busqueda": productosState[0]["Busqueda"], "irInicio": false}, productosState[1]]);
        }
    }*/
  return (
    <div className="MercadoMayoristas">
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        href="https://api.whatsapp.com/send?phone=573016611801&text=Hola 👋🏻 %0D%0AEstoy viendo sus productos en la página web para mayoristas y tengo algunas dudas."
        className="float"
        target="_blank"
      >
        <i className="fa fa-whatsapp my-float"></i>
        <h1 id="circle">¿Necesitas ayuda?</h1>
      </a>
      {/* <div className="mensajeAnuncio" style={{ marginTop: "50px" }}>
        <p className="FuenteHelveticaBold text-center">
          Haz click sobre los productos que quieres ver
        </p>
        <img
          src={
            recursosUrlState + "/img/Index/web/botones/flecha-grande-abajo.webp"
          }
          alt="Señalacion"
          className="flechaGrandeAbajo"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              recursosUrlState +
              "/img/Index/web/botones/flecha-grande-abajo.png";
          }}
        />
      </div> */}
      <CarouselInfo images={images} />
      {/* <div>
      <picture>
          <source srcSet="https://hosting.croatta.com/img/Index/web/home/BANNERMAYORISTAS.webp" media="(min-width: 1400px)" />
          <source srcSet="https://hosting.croatta.com/img/Index/web/home/BANNERMAYORISTAS.webp" media="(min-width: 769px)" />
          <source srcSet="https://hosting.croatta.com/img/Index/web/home/BANNERMAYORISTAS.webp" media="(min-width: 577px)" />
          <img
            srcSet="https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL22.webp"
            alt="responsive image"
            style={{width:"100%"}}
          />
        </picture>
      </div> */}
      <div className="Bar" style={data.GORRAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barGorras}
          onClick={() =>
            actualizarLista(
              "listaGorras",
              "607ed63e521b1c0910a581ac",
              "607ed653521b1c0910a581bb",
              "Hombre",
              null,
              null,
              null,
              barGorras
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/gorras.webp"}
            className="imagenCategoria"
            alt="CategoriaGorras"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = recursosUrlState + "/img/Categorias/gorras.png";
            }}
          />
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Gorras</p>
          {data.GORRAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.GORRAS.Descuento > 0 ? (
            <p className="Descuento">{data.GORRAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}

          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaGorras">
          <ListaProductos
            estadoBar={estadoBar["listaGorras"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.BASICAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barCamisetasHombreBasicas}
          onClick={() =>
            actualizarLista(
              "listaCamisetasHombreBasicas",
              "607ed63e521b1c0910a581aa",
              "607ed653521b1c0910a581b8",
              "Hombre",
              "607ed64c521b1c0910a581b3",
              "607ed65a521b1c0910a581bd",
              null,
              barCamisetasHombreBasicas
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/camisetasHombre.webp"}
            className="imagenCategoria"
            alt="Categoria Camisetas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/camisetasHombre.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Camisetas Básicas</p>
          {data.BASICAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.BASICAS.Descuento > 0 ? (
            <p className="Descuento">{data.BASICAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaCamisetasHombreBasicas">
          <ListaProductos
            estadoBar={estadoBar["listaCamisetasHombreBasicas"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.ESTAMPADAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barCamisetasHombreEstampadas}
          onClick={() =>
            actualizarLista(
              "listaCamisetasHombreEstampadas",
              "607ed63e521b1c0910a581aa",
              "607ed653521b1c0910a581b8",
              "Hombre",
              "607ed64c521b1c0910a581b3",
              "607ed65a521b1c0910a581bf",
              null,
              barCamisetasHombreEstampadas
            )
          }
        >
          {/* <img
            src={
              recursosUrlState + "/img/Categorias/camisetasHombreEstampada.webp"
            }
            className="imagenCategoria"
            alt="Categoria Camisetas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/camisetasHombre.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Camisetas Estampadas</p>
          {data.ESTAMPADAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.ESTAMPADAS.Descuento > 0 ? (
            <p className="Descuento">{data.ESTAMPADAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaCamisetasHombreEstampadas">
          <ListaProductos
            estadoBar={estadoBar["listaCamisetasHombreEstampadas"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.VALTIMOR.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barCamisetasHombreValtimor}
          onClick={() =>
            actualizarLista(
              "listaCamisetasHombreValtimor",
              "607ed63e521b1c0910a581aa",
              "607ed653521b1c0910a581b8",
              "Hombre",
              null,
              null,
              "607ed64c521b1c0910a581b3",
              barCamisetasHombreValtimor
            )
          }
        >
          {/* <img
            src={
              recursosUrlState + "/img/Categorias/camisetasHombreValtimor.webp"
            }
            className="imagenCategoria"
            alt="Categoria Camisetas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Categorias/camisetasHombreValtimor.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Camisetas Valtimor</p>
          {data.VALTIMOR.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.VALTIMOR.Descuento > 0 ? (
            <p className="Descuento">{data.VALTIMOR.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaCamisetasHombreValtimor">
          <ListaProductos
            estadoBar={estadoBar["listaCamisetasHombreValtimor"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.MUJER.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barCamisetasMujer}
          onClick={() =>
            actualizarLista(
              "listaCamisetasMujer",
              "607ed63e521b1c0910a581aa",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barCamisetasMujer
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/camisetaMujer.webp"}
            className="imagenCategoria"
            alt="Categoria Camisetas Mujer"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/camisetaMujer.png";
            }}
          />
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Camisetas Mujer</p>
          {data.MUJER.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.MUJER.Descuento > 0 ? (
            <p className="Descuento">{data.MUJER.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaCamisetasMujer">
          <ListaProductos
            estadoBar={estadoBar["listaCamisetasMujer"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={{display: "none"}}>
        <div
          className="barLinea"
          ref={barCamisetasBasicasMujer}
          onClick={() =>
            actualizarLista(
              "listaCamisetasBasicasMujer",
              "607ed63e521b1c0910a581aa",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              "607ed65a521b1c0910a581bd",
              null,
              barCamisetasBasicasMujer
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/camisetaMujer.webp"}
            className="imagenCategoria"
            alt="Categoria Camisetas Mujer"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/camisetaMujer.png";
            }}
          />
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Camisetas Básicas Mujer</p>
          {data.CB_MUJER.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.CB_MUJER.Descuento > 0 ? (
            <p className="Descuento">{data.CB_MUJER.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaCamisetasBasicasMujer">
          <ListaProductos
            estadoBar={estadoBar["listaCamisetasBasicasMujer"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.POLOS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barPolosHombre}
          onClick={() =>
            actualizarLista(
              "listaPolosHombre",
              "607ed63f521b1c0910a581b1",
              "607ed653521b1c0910a581b8",
              "Hombre",
              null,
              null,
              null,
              barPolosHombre
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/polosHombre.webp"}
            className="imagenCategoria"
            alt="Categoria Polos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/polosHombre.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Polos</p>
          {data.POLOS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.POLOS.Descuento > 0 ? (
            <p className="Descuento">{data.POLOS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaPolosHombre">
          <ListaProductos
            estadoBar={estadoBar["listaPolosHombre"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.BLUSAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barBlusas}
          onClick={() =>
            actualizarLista(
              "listaBlusas",
              "651aec4251072238a0969749",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barBlusas
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/blusa.webp"}
            className="imagenCategoria"
            alt="Categoria Blusas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/blusa.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Blusas</p>
          {data.BLUSAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.BLUSAS.Descuento > 0 ? (
            <p className="Descuento">{data.BLUSAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaBlusas">
          <ListaProductos
            estadoBar={estadoBar["listaBlusas"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar" style={data.PANTALONES_H.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barPolosHombre}
          onClick={() =>
            actualizarLista(
              "listaPantalones",
              "66ab94a0384bbb45fbe9ee0c",
              "607ed653521b1c0910a581b8",
              "Hombre",
              null,
              null,
              null,
              barPantalones
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/polosHombre.webp"}
            className="imagenCategoria"
            alt="Categoria Polos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/polosHombre.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Pantalones Cargo</p>
          {data.PANTALONES_H.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.PANTALONES_H.Descuento > 0 ? (
            <p className="Descuento">{data.PANTALONES_H.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaPantalones">
          <ListaProductos
            estadoBar={estadoBar["listaPantalones"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>

      <div className="Bar" style={data.PANTALONETAS.Disponible? {} : {display: "none"}}>
        <div
          className="barLinea"
          ref={barPantalonetasHombre}
          onClick={() =>
            actualizarLista(
              "listaPantalonetasHombre",
              "607ed63e521b1c0910a581b0",
              "607ed653521b1c0910a581b8",
              "Hombre",
              null,
              null,
              null,
              barPantalonetasHombre
            )
          }
        >
          {/* <img
            src={recursosUrlState + "/img/Categorias/pantalonetas.webp"}
            className="imagenCategoria"
            alt="Categoria Pantalonetas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/pantalonetas.png";
            }}
          ></img>
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Pantalonetas</p>
          {data.PANTALONETAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.PANTALONETAS.Descuento > 0 ? (
            <p className="Descuento">{data.PANTALONETAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaPantalonetasHombre">
          <ListaProductos
            estadoBar={estadoBar["listaPantalonetasHombre"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      {/* <div className="Bar">
        <div
          className="barLinea"
          ref={barVestidos}
          onClick={() =>
            actualizarLista(
              "listaVestidos",
              "65578d4701bf5211b63d14bc",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barVestidos
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/vestido.webp"}
            className="imagenCategoria"
            alt="Categoria Vestidos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/vestido.png";
            }}
          ></img>
          <div className="vertical-line"></div>
          <p className="categoriaBar">Vestidos</p>
          {data.VESTIDOS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.VESTIDOS.Descuento > 0 ? (
            <p className="Descuento">{data.VESTIDOS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaVestidos">
          <ListaProductos
            estadoBar={estadoBar["listaVestidos"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div> */}
      {/* <div className="Bar">
        <div
          className="barLinea"
          ref={barLycras}
          onClick={() =>
            actualizarLista(
              "listaLycras",
              "6525a7906b44bb8bf3323210",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barLycras
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/lycra.webp"}
            className="imagenCategoria"
            alt="Categoria Lycras"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/lycra.png";
            }}
          ></img>
          <div className="vertical-line"></div>
          <p className="categoriaBar">Lycras</p>
          {data.LYCRAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.LYCRAS.Descuento > 0 ? (
            <p className="Descuento">{data.LYCRAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaLycras">
          <ListaProductos
            estadoBar={estadoBar["listaLycras"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div> */}
      {/* <div className="Bar">
        <div
          className="barLinea"
          ref={barBodys}
          onClick={() =>
            actualizarLista(
              "listaBodys",
              "655bad5a1cdc932b9e974a01",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barBodys
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/body.webp"}
            className="imagenCategoria"
            alt="Categoria Bodys"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/body.png";
            }}
          ></img>
          <div className="vertical-line"></div>
          <p className="categoriaBar">Bodys</p>
          {data.BODYS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.BODYS.Descuento > 0 ? (
            <p className="Descuento">{data.BODYS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaBodys">
          <ListaProductos
            estadoBar={estadoBar["listaBodys"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div> */}
      {/* <div className="Bar">
        <div
          className="barLinea"
          ref={barCamisas}
          onClick={() =>
            actualizarLista(
              "listaCamisas",
              "655bac441cdc932b9e9749ff",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barCamisas
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/camisa.webp"}
            className="imagenCategoria"
            alt="Categoria Camisas"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/camisa.png";
            }}
          ></img>
          <div className="vertical-line"></div>
          <p className="categoriaBar">Camisas</p>
          {data.CAMISAS.Nuevo ? (
            <p className="newProduct" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.CAMISAS.Descuento > 0 ? (
            <p className="Descuento">{data.CAMISAS.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaCamisas">
          <ListaProductos
            estadoBar={estadoBar["listaCamisas"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div> */}
      {/*
      <div className="Bar">
        <div
          className="barLinea"
          ref={barHoodiesHombre}
          onClick={() =>
            actualizarLista(
              "listaHoodiesHombre",
              "607ed63e521b1c0910a581ad",
              "607ed653521b1c0910a581b8",
              "Hombre",
              null,
              null,
              null,
              barHoodiesHombre
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/hoodiesHombre.webp"}
            className="imagenCategoria"
            alt="Categoria Hoodies"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/hoodiesHombre.png";
            }}
          ></img>
          <div className="vertical-line"></div>
          <p className="FuenteBebas categoriaBar">Hoodies Hombre</p>
          {data.HOODIES_H.Nuevo ? (
            <p className="Descuento" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.HOODIES_H.Descuento > 0 ? (
            <p className="Descuento">{data.HOODIES_H.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaHoodiesHombre">
          <ListaProductos
            estadoBar={estadoBar["listaHoodiesHombre"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar">
        <div
          className="barLinea"
          ref={barHoodiesMujer}
          onClick={() =>
            actualizarLista(
              "listaHoodiesMujer",
              "607ed63e521b1c0910a581ad",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barHoodiesMujer
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/hoodiesMujer.webp"}
            className="imagenCategoria"
            alt="Categoria Hoodies Mujer"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/hoodiesMujer.png";
            }}
          />
          <div className="vertical-line"></div>
          <p className="FuenteBebas categoriaBar">Hoodies Mujer</p>
          {data.HODDIES_M.Nuevo ? (
            <p className="Descuento" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.HODDIES_M.Descuento > 0 ? (
            <p className="Descuento">{data.HODDIES_M.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaHoodiesMujer">
          <ListaProductos
            estadoBar={estadoBar["listaHoodiesMujer"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar">
        <div
          className="barLinea"
          ref={barJoggersHombre}
          onClick={() =>
            actualizarLista(
              "listaJoggersHombre",
              "607ed63e521b1c0910a581af",
              "607ed653521b1c0910a581b8",
              "Hombre",
              null,
              null,
              null,
              barJoggersHombre
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/joggersHombre.webp"}
            className="imagenCategoria"
            alt="Categoria Joggers"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/joggersHombre.png";
            }}
          ></img>
          <div className="vertical-line"></div>
          <p className="FuenteBebas categoriaBar">Joggers Hombre</p>
          {data.JOGGER_H.Nuevo ? (
            <p className="Descuento" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.JOGGER_H.Descuento > 0 ? (
            <p className="Descuento">{data.JOGGER_H.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          ></img>
        </div>
        <hr className="LineaBar" />
        <div id="listaJoggersHombre">
          <ListaProductos
            estadoBar={estadoBar["listaJoggersHombre"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <div className="Bar">
        <div
          className="barLinea"
          ref={barJoggersMujer}
          onClick={() =>
            actualizarLista(
              "listaJoggersMujer",
              "607ed63e521b1c0910a581af",
              "607ed653521b1c0910a581b9",
              "Mujer",
              null,
              null,
              null,
              barJoggersMujer
            )
          }
        >
          <img
            src={recursosUrlState + "/img/Categorias/joggerMujer.webp"}
            className="imagenCategoria"
            alt="Categoria Hoodies Mujer"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Categorias/joggerMujer.png";
            }}
          />
          <div className="vertical-line"></div>
          <p className="FuenteBebas categoriaBar">Joggers Mujer</p>
          {data.JOGGER_M.Nuevo ? (
            <p className="Descuento" >
              NEW
            </p>
          ) : (
            <></>
          )}
          {data.JOGGER_M.Descuento > 0 ? (
            <p className="Descuento">{data.JOGGER_M.Descuento}% OFF</p>
          ) : (
            <></>
          )}
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaJoggersMujer">
          <ListaProductos
            estadoBar={estadoBar["listaJoggersMujer"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div> */}
      <div className="Bar">
        <div
          className="barLinea"
          ref={barDescuentos}
          onClick={() =>
            actualizarLista(
              "listaDescuentos",
              "Descuentos",
              null,
              "hombre",
              null,
              null,
              null,
              barDescuentos
            )
          }
        >
          {/* <img
            src={"https://hosting.croatta.com/img/Categorias/descuento.png"}
            className="imagenCategoria"
            alt="CategoriaDescuentos"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState + "/img/Header/logos/logo-negro.webp";
            }}
          />
          <div className="vertical-line"></div> */}
          <p className="categoriaBar">Rebajas</p>
          <p className="Descuento">20% OFF</p>
          <img
            src={
              recursosUrlState +
              "/img/Index/web/botones/flecha-abajo-negro.webp"
            }
            className="flechaBar"
            alt="Flecha despliegue"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                recursosUrlState +
                "/img/Index/web/botones/flecha-abajo-negro.png";
            }}
          />
        </div>
        <hr className="LineaBar" />
        <div id="listaDescuentos" style={{ marginBottom: "50px" }}>
          <ListaProductos
            estadoBar={estadoBar["listaDescuentos"]}
            actualizarSessionStorage={actualizarSessionStorage}
            apiUrlState={apiUrlState}
            carrito={carrito}
            clienteState={clienteState}
            errorState={errorState}
            headerState={headerState}
            modificarApiUrlState={modificarApiUrlState}
            modificarCarritoState={modificarCarritoState}
            modificarErrorState={modificarErrorState}
            modificarHeaderState={modificarHeaderState}
            modificarRecursosUrlState={modificarRecursosUrlState}
            modificarSpinnerState={modificarSpinnerState}
            productosState={productosState}
            recursosUrlState={recursosUrlState}
            spinnerState={spinnerState}
            ViewDetalle={ViewDetalle}
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default Mayoristas;
