import axios from "axios";

const apiFacebook = {
  /** Obtener ip */
  get: async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      // console.log('Tu IP es: ', response.data.ip);
      return response.data.ip;  // Retornando el valor de la IP correctamente
    } catch (error) {
      // console.error('Error al obtener la IP:', error);
      return null;  // Retornar null o manejar el error de alguna otra forma
    }
  },

  /** Enviar evento */
  post: async (url, data) => {
    const response = await axios.post(url, data);
    // console.log(response);
  }
}

export default apiFacebook;